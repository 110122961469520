import type { ObjectValues } from '@vrstar/lib/browser';

export const roles = {
  BankAdmin: 'bank_admin',
  ContentAdmin: 'content_admin',
  ContentFeedAgent: 'content_feed_agent',
  OrganizerAgent: 'organizer_agent',
  OrganizerManager: 'organizer_manager',
  PartnerAgent: 'partner_agent',
  PartnerManager: 'partner_manager',
  SystemAdmin: 'system_admin',
  VideocallAgent: 'videocall_agent',
  RealEstateAgent: 'real_estate_agent',
} as const;

export type RolesType = ObjectValues<typeof roles>;

export type UserClaims = {
  bic: string;
  userToken: string;
  userRoles: RolesType[];
  partnerIds: string[];
}
