import type { RouteRecordRaw, RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { useCurrentUser } from 'vuefire';
import { routeNames } from '@/router/types';
import { roles } from '@/types/User';
import { useUserStore } from '@/store/userStore';
import { logout } from '@/utils/logout';
import { selectedBic$ } from '@/utils/selected-bank';
import { ContentCategoryType } from '@vrstar/lib/browser';

async function requireToken(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  if (typeof to.query?.token === 'string') {
    next();
  } else {
    next(from);
  }
}

export const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: routeNames.Login,
    component: () => import('@/views/LoginPage.vue'),
  },
  {
    path: '/registration',
    name: routeNames.Registration,
    component: () => import('@/views/registration/RegistrationPage.vue'),
    beforeEnter: async (_, __, next) => {
      const user = useCurrentUser();

      // check for logged in user, logging out
      if (user.value) {
        await logout();
        next();
        return;
      }

      next();
    },
    children: [

      {
        path: '?token=:token',
        name: routeNames.RegistrationContinue,
        component: () => import('@/views/registration/RegistrationPage.vue'),
      },
      {
        path: 'user-details',
        name: routeNames.RegistrationUser,
        component: () => import('@/views/registration/RegistrationUserPage.vue'),
      },
      {
        path: 'partner-bank',
        name: routeNames.RegistrationBank,
        component: () => import('@/views/registration/RegistrationBankPage.vue'),
      },
      {
        path: 'done',
        name: routeNames.RegistrationDone,
        component: () => import('@/views/registration/RegistrationDonePage.vue'),
      },
    ],
  },
  {
    path: '/account',
    children: [
      {
        path: 'user',
        children: [
          {
            name: routeNames.ConfirmUserEmail,
            path: 'confirm-email',
            component: () => import('@/views/account/ConfirmEmail.vue'),
          },
        ],
      },
      {
        path: 'enduser',
        children: [
          {
            name: routeNames.ConfirmEndUserEmail,
            path: 'confirm-email',
            component: () => import('@/views/account/ConfirmEmail.vue'),
          },
        ],
      },
      {
        name: routeNames.ResetPassword,
        path: 'reset-password',
        component: () => import('@/views/account/PasswordReset.vue'),
        beforeEnter: requireToken,
      },
    ],
  },
  {
    path: '',
    component: () => import('@/components/base/BaseLayout.vue'),
    redirect: { name: routeNames.Home },
    meta: {
      requireAuth: true,
    },
    children: [
      {
        name: routeNames.Home,
        path: '/home',
        component: () => import('@/views/HomePage.vue'),
      },
      {
        path: 'user-management',
        meta: {
          roles: [roles.BankAdmin],
        },
        children: [
          {
            path: '',
            name: routeNames.UserManagement,
            component: () => import('@/views/UserManagementPage.vue'),
          },
          {
            path: 'create-user',
            name: routeNames.CreateUser,
            props: { createNewUser: true },
            component: () => import('@/views/EditBankUserPage.vue'),
          },
          {
            path: ':id',
            name: routeNames.EditBankUser,
            beforeEnter: (to, _, next) => {
              const user = useCurrentUser();

              // redirect current user to user profile view
              if (user.value && user.value.uid === to.params.id) {
                next({ name: routeNames.UserProfile });
                return;
              }

              next();
            },
            component: () => import('@/views/EditBankUserPage.vue'),
          },
        ],
      },
      {
        path: 'offer',
        name: routeNames.PartnerOfferManagement,
        meta: {
          roles: [roles.PartnerAgent],
        },
        redirect: { name: routeNames.ListPartnerOffers },
        children: [
          {
            path: 'new',
            name: routeNames.CreatePartnerOffer,
            component: () => import('@/views/partner/offers/OfferCreate.vue'),
          },
          {
            path: ':id(\\d+)/edit',
            name: routeNames.EditPartnerOffer,
            component: () => import('@/views/partner/offers/OfferEdit.vue'),
          },
          {
            path: '',
            name: routeNames.ListPartnerOffers,
            component: () => import('@/views/partner/offers/OfferList.vue'),
          },
        ],
      },
      {
        path: 'event-management',
        name: routeNames.EventManagement,
        meta: {
          roles: [roles.OrganizerManager, roles.OrganizerAgent, roles.SystemAdmin, roles.ContentAdmin],
        },
        component: () => import('@/views/event-management/EventManagementPage.vue'),
        redirect: { name: routeNames.ListEvents },
        children: [
          {
            path: 'event',
            children: [
              {
                path: 'new',
                name: routeNames.CreateEvent,
                component: () => import('@/views/event-management/event/EventCreate.vue'),
              },
              {
                path: ':id(\\d+)/edit',
                name: routeNames.EditEvent,
                component: () => import('@/views/event-management/event/EventEdit.vue'),
              },
              {
                path: '',
                name: routeNames.ListEvents,
                component: () => import('@/views/event-management/event/EventList.vue'),
              },
            ],
          },
          {
            path: 'organizer',
            children: [
              {
                path: 'new',
                name: routeNames.CreateOrganizer,
                component: () => import('@/views/event-management/organizer/OrganizerCreate.vue'),
              },
              {
                path: ':id(\\d+)/edit',
                name: routeNames.EditOrganizer,
                component: () => import('@/views/event-management/organizer/OrganizerEdit.vue'),
              },
              {
                path: '',
                name: routeNames.ListOrganizers,
                component: () => import('@/views/event-management/organizer/OrganizerList.vue'),
              },
            ],
          },
          {
            path: 'venue',
            children: [
              {
                path: 'new',
                name: routeNames.CreateVenue,
                component: () => import('@/views/event-management/venue/VenueCreate.vue'),
              },
              {
                path: ':id(\\d+)/edit',
                name: routeNames.EditVenue,
                component: () => import('@/views/event-management/venue/VenueEdit.vue'),
              },
              {
                path: '',
                name: routeNames.ListVenues,
                component: () => import('@/views/event-management/venue/VenueList.vue'),
              },
            ],
          },
          {
            path: 'import',
            meta: {
              roles: [roles.OrganizerManager, roles.SystemAdmin, roles.ContentAdmin],
              permissionGuards: {
                async afterRoleCheck({ user }) {
                  const userRoles = user?.idTokenResult.claims?.roles || [];
                  if (userRoles.includes(roles.SystemAdmin) || userRoles.includes(roles.ContentAdmin)) {
                    return !!selectedBic$.value;
                  }

                  return true;
                },
              },
            },
            children: [
              {
                path: ':id/details',
                name: routeNames.EventImportDetails,
                component: () => import('@/views/event-management/import/EventImportDetails.vue'),
              },
              {
                path: '',
                name: routeNames.ListEventImports,
                component: () => import('@/views/event-management/import/EventImportList.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'review',
        name: routeNames.ReviewPage,
        meta: {
          roles: [roles.OrganizerManager, roles.PartnerManager],
        },
        component: () => import('@/views/review/ReviewPage.vue'),
        children: [
          {
            path: 'user',
            redirect: { name: routeNames.ReviewUsersList },
            children: [
              {
                path: ':id',
                name: routeNames.ReviewUserDetail,
                component: () => import('@/views/review/users/ReviewUserDetail.vue'),
              },
              {
                path: '',
                name: routeNames.ReviewUsersList,
                component: () => import('@/views/review/users/ReviewUsersList.vue'),
              },
            ],
          },
          {
            path: 'event',
            redirect: { name: routeNames.ReviewEventsList },
            meta: {
              roles: [roles.OrganizerManager],
            },
            children: [
              {
                path: ':id',
                name: routeNames.ReviewEventDetail,
                component: () => import('@/views/review/events/ReviewEventDetail.vue'),
              },
              {
                path: '',
                name: routeNames.ReviewEventsList,
                component: () => import('@/views/review/events/ReviewEventsList.vue'),
              },
            ],
          },
          {
            path: 'offer',
            redirect: { name: routeNames.ReviewOffersList },
            meta: {
              roles: [roles.PartnerManager],
            },
            children: [
              {
                path: ':id',
                name: routeNames.ReviewOfferDetail,
                component: () => import('@/views/review/offers/ReviewOfferDetail.vue'),
              },
              {
                path: '',
                name: routeNames.ReviewOffersList,
                component: () => import('@/views/review/offers/ReviewOffersList.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'bank-management',
        name: routeNames.BankManagement,
        meta: {
          roles: [roles.SystemAdmin],
          permissionGuards: {
            async afterRoleCheck() {
              return !selectedBic$.value;
            },
          },
        },
        redirect: { name: routeNames.ListBanks },
        children: [
          {
            path: ':bic/edit',
            name: routeNames.EditBank,
            component: () => import('@/views/bank-management/EditBank.vue'),
          },
          {
            path: '',
            name: routeNames.ListBanks,
            component: () => import('@/views/bank-management/BankList.vue'),
          },
        ],
      },
      {
        path: 'bank-profile',
        name: routeNames.BankProfile,
        meta: {
          roles: [roles.BankAdmin, roles.SystemAdmin],
          permissionGuards: {
            async afterRoleCheck({ user }) {
              if (user?.idTokenResult.claims?.roles?.includes(roles.SystemAdmin)) {
                return !!selectedBic$.value;
              }

              return true;
            },
          },
        },
        beforeEnter: (to, from, next) => {
          const userStore = useUserStore();

          if (!userStore.user?.bank?.bic) {
            next(from);
            return;
          }

          next();
        },
        component: () => import('@/views/bank-management/BankProfile.vue'),
      },
      {
        path: 'content-feed',
        name: routeNames.ContentFeed,
        meta: {
          roles: [roles.ContentFeedAgent, roles.SystemAdmin, roles.ContentAdmin],
        },
        redirect: { name: routeNames.ContentFeedList },
        children: [
          {
            path: 'new',
            name: routeNames.ContentFeedCreate,
            component: () => import('@/views/content-feed/CreateContentFeedPage.vue'),
          },
          {
            path: ':id(\\d+)/edit',
            name: routeNames.ContentFeedEdit,
            component: () => import('@/views/content-feed/EditContentFeedPage.vue'),
          },
          {
            path: '',
            name: routeNames.ContentFeedList,
            component: () => import('@/views/content-feed/ListContentFeedPage.vue'),
          },
        ],
      },
      {
        path: 'video-call',
        name: routeNames.VideoCallPage,
        meta: {
          roles: [roles.VideocallAgent],
        },
        component: () => import('@/views/VideoCallPage.vue'),
      },
      {
        path: 'profile',
        name: routeNames.UserProfile,
        component: () => import('@/views/UserProfile.vue'),
      },
      {
        path: 'real-estate',
        name: routeNames.RealEstatePage,
        meta: {
          roles: [roles.BankAdmin, roles.SystemAdmin, roles.ContentAdmin, roles.RealEstateAgent],
          permissionGuards: {
            async afterRoleCheck({ user }) {
              if ((Object.values(user?.bank?.disabledCategories ?? {}).includes(ContentCategoryType.ESTATE))) {
                return false;
              }

              if (user?.idTokenResult.claims?.roles?.includes(roles.SystemAdmin) || user?.idTokenResult.claims?.roles?.includes(roles.ContentAdmin)) {
                return !!selectedBic$.value;
              }

              return true;
            },
          },
        },
        component: () => import('@/views/RealEstatePage.vue'),
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: routeNames.Home },
  },
];
